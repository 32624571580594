import axios from "axios";
class OrganisationService {

    axios(){
        return axios.create({
            baseURL: "/organisation",
            headers:{
                'x-api-key': window.env.VUE_APP_ORGANISATIONSERVICEKEY
            },
            timeout : window.env.VUE_APP_TIMEOUT_SECONDE_SEARCH_BAR * 1000
        })
    }
    getCompanyByName(query) {
        return this.axios().post("/companies/FR/search?page=1&limit=10",(query));
    }

    getSiteByCompany(companyId) {
        return this.axios().post("/companies/FR/sites/search?page=1&limit=50",({"companyRegistrationId":companyId}));
    }

}
export default new OrganisationService();