// App
import {createApp} from 'vue'
import App from './App.vue'
import { store } from './store.js'
import axios from "axios";
import VueNumberFormat from 'vue-number-format';
import {createWebHistory, createRouter} from "vue-router";
import { routes } from "./router/router.js";
import ElementPlus from 'element-plus';
import fr from 'element-plus/lib/locale/lang/fr'
import 'element-plus/dist/index.css';
import Notifications from '@kyvg/vue3-notification'
import Vue3Lottie from 'vue3-lottie'

// Fonts
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import {FontAwesomeIcon} from "./plugins/font-awesome";
import './assets/css/main.css';
import './assets/css/app.css';
import moment from 'moment';
import mitt from 'mitt';
const emitter = mitt();

axios.defaults.headers.common["Content-Type"] = "application/x-www-form-urlencoded";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

const router = createRouter({
    history: createWebHistory(),
    routes,
});


const app = createApp(App)
    .use(ElementPlus, {
        locale: fr
    })
    .use(Vue3Lottie, { name: 'LottieAnimation' })
    .use(Notifications)
    .use(store)
    .use(router)
    .use(moment)
    .use(VueNumberFormat, {prefix: ' ', suffix: ' €', decimal: ',', thousand: ' ', precision: 2})
    .component("fa", FontAwesomeIcon)

app.config.globalProperties.emitter = emitter;
app.config.globalProperties.$http = axios;
app.config.globalProperties.env = window.env;

router.beforeEach((to, from, next) => {

    if(store.getters.getToken !== null && store.getters.getToken) {
        app.config.globalProperties.$http.defaults.headers.common['DOLAPIKEY'] = store.getters.getToken;
    }

    if(app.config.globalProperties.env.VUE_APP_MAINT === 'true'){
        if(to.path !== "/service/maintenance"){
            router.push('/service/maintenance');
        }
    }else{
        if((store.getters.getUser === null && to.path !== "/login")){
            router.push('/login');
        }
        //Hide maintenance page. Unregistrer user will be redirect to login page
        if(to.path === "/service/maintenance"){
            router.push('/');
        }
        //Handle disconnect after 1 day
        if(!store.getters.getDate){
            let date = new Date()
            date.setDate(date.getDate() + 1)
            store.commit('setDate', date.getTime())
        }

        if(store.getters.getDate < new Date().getTime()){
            store.commit('setUser', null);
        }
    }
    next();
});
app.mount('#app');

